.main_div_application_form {
  background: url(../../assets/bg.webp) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  padding: 20px;
}

.application_form_main_box {
  -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 10%, 90% 0);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 8%, 90% 0);
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  background-color: #f4f4f4;
  padding: 80px;
}

@media only screen and (max-width: 600px) {
  .application_form_main_box {
    width: 100%;
    padding: 25px;
  }
}
