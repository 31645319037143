*root {
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #f4f8fb;
  margin: 0;
  padding: 0px;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins"), url(./assets/fonts/Poppins-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: local("Poppins"),
    url(./assets/fonts/Poppins-ExtraBold.ttf) format("truetype");
}
