/* .Toastify__toast-theme--colored.Toastify__toast--default {
  background-color: pink;
} */

.Toastify__toast-body {
  font-family: "Poppins";
}
.Toastify__close-button > svg {
  color: black;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: black !important;
  background-color: white !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: black !important;
  background-color: white !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success
  .Toastify__toast-icon
  > svg {
  fill: #040f25;
}
.Toastify__toast-theme--colored.Toastify__toast--error
  .Toastify__toast-icon
  > svg {
  fill: #d52027;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
  background-color: #040f25 !important;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background-color: #d52027 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .Toastify__progress-bar-theme--light {
  color: aqua;
}
.Toastify__toast-theme--light {
  color: #040F25;
}
.Toastify__close-button {
  color: black;
} */
/* 
.Toastify__close-button > svg {
  color: black;
} */
/* .Toastify__progress-bar-theme--light {
  color: pink;
}
.Toastify__progress-bar--success {
  color: blueviolet;
} */
